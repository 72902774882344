<template>
	<div>
		<div>
			<headerTop></headerTop>
		</div>
		<div>
			<headerMin :searchState="searchState"></headerMin>
		</div>
		<div class="doctorWait_content wrap">
			<div class="wa_indexes"> <span @click="$router.push('/')">首页</span> >
				<span @click="$router.push('/doctor/index')">线上问诊</span> > {{ this.doctorInfo.category_name }} > {{ this.doctorInfo.realname }}</div>
			<!-- 步骤 -->
			<div class="wa_step">
				<el-steps :active="state" align-center>
					<el-step title="选择医生" description></el-step>
					<el-step title="选择问诊方式" description></el-step>
					<el-step title="等待接诊" description></el-step>
					<el-step title="线上问诊" description></el-step>
				</el-steps>
			</div>
			<!-- 提示信息 -->
			<div class="wa_tips">
				<p>
					医师正在问诊中，请耐心等待，您当前处于第
					<span>{{ waitingNum  }}</span> 位。
				</p>等待期间，您可以填写用户档案，便于节约您的问诊时间。
			</div>
			<!-- 档案 -->
			<div class="wa_record">
				<div class="title">
					用户档案
					<div @click="addRecord = 1" class="fr">添加档案</div>
				</div>
				<div v-if="recordType == 0" class="no">暂无用户档案</div>
				<div v-if="recordType == 1" class="wa_list">
					<div class="wa_user" :class="{blue:isactive==i}" v-for="(v, i) in archivesList" :key="i" @click="choice(v,i)">
						<p class="wa_name one">
							{{ v.realname }}
							<span></span>
							{{ v.sex == 0 ? '保密' : (v.sex == 1 ? '男' : '女') }}
						</p>
						<p class="one phone">{{ v.mobile }}</p>
						<p class="two">{{ v.disease_desc }}</p>
						<div class="add_bottom">
							{{ v.birthday }}
							<div class="fr">
								<span class="add_record_set" @click.stop="modifyArchives(v)">修改</span>
								<span class="add_record_remove" @click="delDangan(v)">删除</span>
							</div>
						</div>
					</div>
				</div>
			</div>
			<!-- 添加档案 -->
			<div @click="addRecord = 0" v-show="addRecord == 1" class="addRecord">
				<div class="addRecord_box">
					<div @click.stop class="ad_title">
						新增档案信息
						<img @click="addRecord = 0" class="none" src="@/assets/image/Interrogation_Popup_Close@2x.png" alt />
					</div>
					<div @click.stop class="ar_box">
						<div class="ar_txt">
							<div class="ar_txts">
								姓
								<i></i>
								<i></i>
								<i></i>
								<i></i> 名
							</div>
							<input v-model="name" type="text" maxlength="12" />
						</div>
						<div class="ar_txt">
							<div class="ar_txts">
								性
								<i></i>
								<i></i>
								<i></i>
								<i></i> 别
							</div>
							<div class="as">
								<el-radio v-model="sex" label="1">男</el-radio>
								<el-radio v-model="sex" label="2">女</el-radio>
							</div>
						</div>
						<div class="ar_txt w60">
							<div class="ar_txts">
								年
								<i></i>
								<i></i>
								<i></i>龄
							</div>
							<el-date-picker v-model="birthday" type="date" placeholder="请选择日期"></el-date-picker>
						</div>
						<div class="ar_txt w60">
							<div class="ar_txts">
								身
								<i></i>
								<i></i>
								<i></i> 高
							</div>
							<input v-model="h" type="text" maxlength="3" />C M
						</div>
						<div class="ar_txt w60">
							<div class="ar_txts">
								体
								<i></i>
								<i></i>
								<i></i> 重
							</div>
							<input v-model="kg" type="text" maxlength="3" />K G
						</div>
						<div class="ar_txt">
							<div class="ar_txts">电话</div>
							<input v-model="mobile" type="text" maxlength="11" />
						</div>
						<div class="ar_txt">
							<div class="ar_txts">现住址</div>
							<input v-model="address" type="text" maxlength="11" />
						</div>
						<div class="ar_txt">
							<div class="ar_txts">联系人</div>
							<input v-model="linkman" type="text" maxlength="11" />
						</div>
						<div class="ar_txt">
							<div class="ar_txts">与联系人关系</div>
							<el-select v-model="relationship" placeholder="请选择" @change="getname(2)">
								<el-option v-for="item in relatlist" :key="item.code" :label="item.name" :value="item.code">
								</el-option>
							</el-select>
						</div>
						<div class="ar_txt">
							<div class="ar_txts">联系人住址</div>
							<input v-model="linkmanAddr" type="text" maxlength="11" />
						</div>
						<div class="ar_txt">
							<div class="ar_txts">联系人电话</div>
							<input v-model="linkmanTele" type="text" maxlength="11" />
						</div>
						<div class="ar_txt iscard">
							<div class="ar_txts">身份证号</div>
							<input v-model="idcard" type="text" maxlength="18" />
						</div>
						<div class="ar_txt">
							<div class="ar_txts">
								过
								<i></i>敏
								<i></i>史
							</div>
							<textarea v-model="guominshi"></textarea>
						</div>
						<div class="ar_txt">
							<div class="ar_txts">
								遗
								<i></i>传
								<i></i>史
							</div>
							<textarea v-model="yichuanshi"></textarea>
						</div>
						<div>
							<span>付费方式</span>
							<el-select v-model="medpaytype" placeholder="请选择">
								<el-option v-for="item in medpaytypelist" :key="item.code" :label="item.name" :value="item.name">
								</el-option>
							</el-select>
						</div>
						<div class="ar_txt" style="margin-top: 10px;">
							<div class="ar_txts">
								上传检查报告
							</div>
							<el-upload :action="imageUrl" list-type="picture-card" :on-success="ListImgSuccess" :before-upload="beforeAvatarUpload"
							 :on-remove="ListImgRemove">
								<i class="el-icon-plus"></i>
							</el-upload>
						</div>
						<p @click="checking()">保存</p>
					</div>
				</div>
			</div>
			<!-- 修改档案 -->
			<div @click="editRecord = 0" v-show="editRecord == 1" class="addRecord">
				<div class="addRecord_box">
					<div @click.stop class="ad_title">
						修改档案信息
						<img @click="editRecord = 0" class="none" src="@/assets/image/Interrogation_Popup_Close@2x.png" alt />
					</div>
					<div @click.stop class="ar_box">
						<div class="ar_txt">
							<div class="ar_txts">
								姓
								<i></i>
								<i></i>
								<i></i>
								<i></i> 名
							</div>
							<input v-model="currentRecord.realname" type="text" maxlength="12" />
						</div>
						<div class="ar_txt">
							<div class="ar_txts">
								性
								<i></i>
								<i></i>
								<i></i>
								<i></i> 别
							</div>
							<div class="as">
								<el-radio v-model="radio" label="1">男</el-radio>
								<el-radio v-model="radio" label="2">女</el-radio>
							</div>
						</div>
						<div class="ar_txt w60">
							<div class="ar_txts">
								年
								<i></i>
								<i></i>
								<i></i>龄
							</div>
							<el-date-picker v-model="currentRecord.birthday" type="date" :placeholder="currentRecord.birthday ? currentRecord.birthday : '选择日期'"></el-date-picker>
						</div>
						<div class="ar_txt w60">
							<div class="ar_txts">
								身
								<i></i>
								<i></i>
								<i></i> 高
							</div>
							<input v-model="currentRecord.height" type="text" maxlength="3" />C M
						</div>
						<div class="ar_txt w60">
							<div class="ar_txts">
								体
								<i></i>
								<i></i>
								<i></i> 重
							</div>
							<input v-model="currentRecord.weight" type="text" maxlength="3" />K G
						</div>
						<div class="ar_txt ">
							<div class="ar_txts">电话</div>
							<input v-model="currentRecord.mobile" type="text" maxlength="11" />
						</div>
						<div class="ar_txt">
							<div class="ar_txts">现住址</div>
							<input v-model="currentRecord.address" type="text" maxlength="11" />
						</div>
						<div class="ar_txt">
							<div class="ar_txts">联系人</div>
							<input v-model="currentRecord.linkman" type="text" maxlength="11" />
						</div>
						<div class="ar_txt">
							<div class="ar_txts">与联系人关系</div>
							<el-select v-model="currentRecord.relationship" placeholder="请选择" @change="getname(1)">
								<el-option v-for="item in relatlist" :key="item.code" :label="item.name" :value="item.code">
								</el-option>
							</el-select>
						</div>
						<div class="ar_txt">
							<div class="ar_txts">联系人住址</div>
							<input v-model="currentRecord.linkmanAddr" type="text" maxlength="11" />
						</div>
						<div class="ar_txt">
							<div class="ar_txts">联系人电话</div>
							<input v-model="currentRecord.linkmanTele" type="text" maxlength="11" />
						</div>
						<div class="ar_txt iscard">
							<div class="ar_txts">身份证号</div>
							<input v-model="currentRecord.idcard" type="text" maxlength="18" />
						</div>
						<div class="ar_txt">
							<div class="ar_txts">
								过
								<i></i>敏
								<i></i>史
							</div>
							<textarea v-model="currentRecord.allergy"></textarea>
						</div>
						<div class="ar_txt">
							<div class="ar_txts">
								遗
								<i></i>传
								<i></i>史
							</div>
							<textarea v-model="currentRecord.genetic_disease"></textarea>
						</div>
						<div>
							<span>付费方式</span>
							<el-select v-model="currentRecord.medicalPayment" placeholder="请选择">
								<el-option v-for="item in medpaytypelist" :key="item.code" :label="item.name" :value="item.code">
								</el-option>
							</el-select>
						</div>
						<div class="ar_txt" style="margin-top:10px;">
							<div class="ar_txts">
								上传检查报告
							</div>
							<div>
								<el-upload :action="imageUrl" list-type="picture-card"  :file-list="filelist" :on-success="ListImgSuccess"
								 :before-upload="beforeAvatarUpload" :on-remove="ListImgRemove">
									<i class="el-icon-plus"></i>
								</el-upload>
							</div>
						</div>
						<p @click="savaModifyArchives()">保存</p>
					</div>
				</div>
			</div>
			<!-- 底部 -->
			<div class="wa_bottom" @click="$router.push('/doctor/index')">退出等待</div>
		</div>
		<div class="bg1">
			<publicBottom></publicBottom>
		</div>
	</div>
</template>
<script>
	import headerTop from "@/components/public/public_headerTop.vue";
	import headerMin from "@/components/index/index_header_min";
	import publicBottom from "@/components/public/public_bottom.vue";
	import {
		getWaittingNum
	} from "@/request/index"; // 获取等待人数
	import {
		getinfo
	} from "@/request/index"; // 医生详情
	import {
		add_dangan
	} from "@/request/user"; // 添加档案
	import {
		dangan_getList
	} from "@/request/user"; // 档案列表
	import {
		del_dangan
	} from "@/request/user";
	import {
		leave_wait
	} from "@/request/index";
	import {
		setPatientInfo
	} from "@/request/personnel"; //提交档案
	import {
		getBedInfor
	} from "@/request/personnel";
	import moment from "moment";
	import {
		mapState,
		mapMutations
	} from "vuex";
	export default {
		components: {
			headerTop,
			headerMin,
			publicBottom
		},
		props: [],
		beforeRouteLeave(to, from, next) {
			if (to.path == "/onlineView" || to.path == "/video") {
				next();
			} else {
				leave_wait({
					token: this.$user.token,
					inquiry_doctor_id: this.inquiry_doctor_id
				}).then(res => {
					if (res.code == 1) {
						this.$message({
							type: "success",
							message: "你已经退出当前等待"
						});
					}
				});
				next();
			}
		},
		data() {
			return {
				linkmanTele: "", //联系人电话
				linkmanAddr: "", //联系人地址
				relationship: "", //联系人关系
				linkman: "", //联系人
				address: "", //地址
				relationship_name: "",
				searchState: 0,
				search: "",
				state: 3, // 步骤条
				name: "",
				sex: "1", //1男   2 女
				age: "",
				h: "",
				kg: "",
				birthday: "",
				mobile: "",
				idcard: "",
				guominshi: "",
				yichuanshi: "",
				bingqing: "",
				birthday_value: "",
				radio: 0,
				imgArr: [], //资质证明 图的 list
				recordType: 1, // 是否有档案信息
				addRecord: 0, // 增加 档案信息 1显示
				editRecord: 0, // 修改档案
				currentRecord: {}, // 当前修改的档案
				archivesList: [], // 用户档案列表
				doctorInfo: {}, // 医生详情
				waitingNum: "",
				index: 0,
				isactive: "-1",
				medpaytypelist: [],
				medpaytype: "全自费",
				relatlist: [],
				filelist:[],
			};
		},
		computed: {
			...mapState(["waiting_num"])
		},
		watch: {
			waiting_num(val) {
				this.index++;
				if (this.index == 1) return;
				this.waitingNum = val + 1;
			}
		},
		created() {
			let datas = localStorage.getItem("lzyyToken");
			this.id = this.$route.query.id;
			this.uid = this.$route.query.uid;
			this.inquiry_doctor_id = this.$route.query.inquiry_doctor_id;
			this.inquiry_id = this.$route.query.inquiry_id;
			let Athis = this;
			this.waitNum = this.waiting_num;
			console.log(this.waiting_num, this.waitNum);
			if (datas != null) {
				this.$tokens = datas;
			} else {
				this.$message.error("登录错误，请重新登录");
				setTimeout(function() {
					Athis.$router.replace({
						path: "/land"
					});
				}, 1000);
			}
			this.start();
			getBedInfor({
				table: "MEDPAYTYPE"
			}).then(res => {
				this.medpaytypelist = res.data;
				// console.log(res);
			});
			getBedInfor({
				table: "DOMESTICRELATION"
			}).then(res => {
				this.relatlist = res.data;
			});
		},
		methods: {
			getname(val) {
				let obj = {};
				if (val == 1) {
					obj = this.relatlist.find((item) => {
						return item.code == this.currentRecord.relationship;
					});
					this.currentRecord.relationship_name = obj.name;
				} else {
					obj = this.relatlist.find((item) => {
						return item.code == this.relationship;
					});
					this.relationship_name = obj.name;
				}

			},
			getcode(name) {
				let code = "";
				for (let i in this.medpaytypelist) {
					if (this.medpaytypelist[i].name == name) {
						code = this.medpaytypelist[i].code;
					}
				}
				return code;
			},
			choice(v, i) {
				setPatientInfo({
					token: this.$token,
					inquiry_id: this.inquiry_id,
					patient_id: v.id
				}).then(res => {
					if (res.code == 1) {
						this.$message({
							message: "上传成功",
							type: "success"
						});
					} else {
						this.$message({
							message: "上传失败",
							type: "error"
						});
					}
				})
				console.log(v);
				this.isactive = i;
			},
			start() {
				// 医生信息
				getinfo({
					id: this.id
				}).then(res => {
					this.doctorInfo = res.data;
				});
				// 当前等待人数
				getWaittingNum({
					doctor_uid: this.uid
				}).then(res => {
					if (res.code == 1) {
						this.setWaitingNum(res.data.waitting_num);
					}
				});
				// 获取档案列表
				this.getDanganList();
			},
			send() {
				let imgData = [];
				if (this.imgArr != "") {
					for (let i = 0; i < this.imgArr.length; i++) {
						imgData.push(this.imgArr[i].url);
					}
					imgData = imgData.join(",");
				}
				add_dangan({
					token: this.$user.token,
					realname: this.name,
					mobile: this.mobile,
					sex: this.sex,
					birthday: this.birthday,
					weight: this.kg,
					height: this.h,
					linkmanTele: this.linkmanTele,
					linkmanAddr: this.linkmanAddr,
					relationship: this.relationship,
					linkman: this.linkman,
					address: this.address,
					relationship_name: this.relationship_name,
					allergy: this.guominshi,
					genetic_disease: this.yichuanshi,
					disease_desc: this.bingqing,
					report_urls: imgData,
					idcard: this.idcard,
					medicalPayment: this.getcode(this.medpaytype)
				}).then(res => {
					console.log(res);
					if (res.code == 1) {
						this.imgArr = [];
						// this.start();
						this.addRecord = 0;
						this.$message({
							message: "保存成功",
							type: "success"
						});
						this.addRecord = 0;
						this.getDanganList();
					} else {
						this.$message.error(res.msg);
					}
				});
			},
			checking() {
				if (this.name == "") {
					this.$message.error("姓名不能为空");
				} else if (this.birthday == "") {
					this.$message.error("年龄不能为空");
				} else if (this.h == "") {
					this.$message.error("身高不能为空");
				} else if (this.kg == "") {
					this.$message.error("体重不能为空");
				} else if (this.mobile == "") {
					this.$message.error("联系电话不能为空");
				} else if (this.iscard == "") {
					this.$message.error("身份证号不能为空");
				} else if (this.guominshi == "") {
					this.$message.error("过敏史不能为空");
				} else if (this.yichuanshi == "") {
					this.$message.error("遗传史不能为空");
				} else {
					this.send();
					// 更新档案视图
					this.getDanganList();
				}
			},
			modifyArchives(v) {
				// 修改档案
				this.radio = v.sex + "";
				this.currentRecord = { ...v
				};
				for (let i in this.medpaytypelist) {
					if (this.medpaytypelist[i].code == v.medicalPayment) {
						this.currentRecord.medicalPayment = this.medpaytypelist[i].name;
					}
				}
				for(let item of v.report_arr){
					this.filelist.push({
						name:item,
						url:item,
					})
				}
				this.editRecord = 1;
			},
			savaModifyArchives() {
				let imgData = [];
				if (this.imgArr != "") {
					for (let i = 0; i < this.imgArr.length; i++) {
						imgData.push(this.imgArr[i].url);
					}
					imgData = imgData.join(",");
				}
				add_dangan({
					token: this.$token,
					...this.currentRecord,
					sex: this.radio,
					report_urls: imgData
				}).then(res => {
					if (res.code == 1) {
						this.$message.success("操作成功");
						this.getDanganList();
						this.editRecord = 0;
					} else {
						this.$message.error(res.msg);
					}
				});
			},
			GoAllGoods() {
				this.$router.replace({
					path: "/allGoods"
				});
			},
			beforeAvatarUpload(file) {
				const isJPG = file.type === "image/jpeg";
				const isLt2M = file.size / 1024 / 1024 < 2;
				if (!isJPG) {
					this.$message.error("上传图片只能是 JPG 格式!");
				}
				if (!isLt2M) {
					this.$message.error("上传图片大小不能超过 2MB!");
				}
				return isJPG && isLt2M;
			},
			//   资质证明 图的 list
			ListImgSuccess(response, file, fileList) {
				let imgurl = response.data.file.url;
				file.url = imgurl;
				this.imgArr = fileList;
			},
			//   资质证明 删除
			ListImgRemove(file, fileList) {
				this.imgArr = fileList;
			},
			//   获取档案列表
			getDanganList() {
				dangan_getList({
					token: this.$user.token,
					id: this.id
				}).then(res => {
					this.archivesList = res.data.data;
					for (var i = 0; i < this.archivesList.length; i++) {
						this.archivesList[i].birthday = moment(
							this.archivesList[i].birthday * 1000
						).format("YYYY-MM-DD");
					}
					if (this.archivesList.length == 0) this.recordType = 0;
					else this.recordType = 1;
				});
			},
			//   删除档案
			delDangan(v) {
				del_dangan({
					token: this.$user.token,
					id: v.id
				}).then(res => {
					if (res.code == 1) {
						this.$message({
							type: "success",
							message: "删除成功"
						});
					}
				});
				this.archivesList = [...this.archivesList.filter(item => item != v)];
			},
			//   退出等待
			leaveWait() {
				leave_wait({
					token: this.$user.token,
					inquiry_doctor_id: this.inquiry_doctor_id
				}).then(res => {
					if (res.code == 1) {
						this.$message({
							type: "success",
							message: "你已经退出当前等待"
						});
					}
				});
			},
			...mapMutations(["setWaitingNum"])
		}
	};
</script>
<style lang="less" scoped>
	@import "./doctor";

	.blue {
		border-color: #4EEEC8 !important;
	}
</style>
